.global-nav {
    position: sticky;
    z-index: 10;
    top: 0px;
    left: 0px;
}

.global-nav__backlink-text {
    display: flex;
    justify-content: center;
    align-items: center;

}

.global-nav__items {
    margin: 0;
    padding: 0 24px;
    gap: 10px;
    height: 58px;
    background: #FFF;
    box-shadow: inset 0 -1px 0 #EEEDF2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.logo-item {
    flex: 1 0;
}

.logo {
    width: 130.95px;
    height: 23.53px;
}

.components-item,
.help-item {
    margin-right: 48px;

}

.global-nav__items a {
    text-decoration: none;
    color: #1E0A3C;
    font-family: 'Neue Plak Text';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Neue Plak Text', sans-serif;
}