.ticker-banner {
    position: absolute;
    bottom: 32px;
    padding: 12px;
    background-color: #8FF2F7;
}

.ticket-banner__text {
    left: 12px;
    width: 3162px;
    color: #0124E9;
    font-weight: 800;
    font-size: 84px;
    line-height: 104px;
    font-family: 'Neue Plak';
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    -webkit-text-fill-color: #8FF2F7;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #0124E9;
}