.app {
  position: relative;
}

.desktop-layout {
  width: 100vw;
}

.global-nav {
  position: sticky;
  z-index: 2;
}

.global-nav__items {
  margin: 0;
  padding: 0 24px;
  gap: 10px;
  height: 58px;
  background: #FFF;
  box-shadow: inset 0 -1px 0 #EEEDF2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.logo-item {
  flex: 1 0;
}

.logo {
  width: 130.95px;
  height: 23.53px;
}

.components-item,
.help-item {
  margin-right: 48px;

}

.global-nav__items a {
  text-decoration: none;
  color: #1E0A3C;
  font-family: 'Neue Plak Text';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Neue Plak Text', sans-serif;
}

.timer-banner {
  z-index: 2;
  position: absolute;
  height: 50px;
  background-color: #F7F154;
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Bebas Neue', cursive;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #1E0A3C;
  width: 100%;
}

b {
  font-size: 32px;
}

/* FIGMA GEN HTML */

.ei22_4675_14_1660_14_556 {
  transform: rotate(-180deg);
  width: 394.9296569824219px;
  height: 0.24726556241512299px;
  position: absolute;
  left: 397.21234130859375px;
  top: 43.25222396850586px;
}

.i22_4675_14_1660_14_556 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1660_14_557 {
  transform: rotate(-180deg);
  width: 1.093909740447998px;
  height: 43.201454162597656px;
  position: absolute;
  left: 397.21234130859375px;
  top: 43.201454162597656px;
}

.i22_4675_14_1660_14_557 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1660_14_558 {
  transform: rotate(-180deg);
  width: 393.27734375px;
  height: 0;
  position: absolute;
  left: 393.4833068847656px;
  top: 1.3029756546020508px;
}

.i22_4675_14_1660_14_558 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1660_14_559 {
  transform: rotate(-180deg);
  width: 0.4058450758457184px;
  height: 42.40964126586914px;
  position: absolute;
  left: 0.4061373174190521px;
  top: 43.469879150390625px;
}

.i22_4675_14_1660_14_559 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1660_14_560 {
  transform: rotate(-180deg);
  width: 1.8258600234985352px;
  height: 41.34939956665039px;
  position: absolute;
  left: 1.8262804746627808px;
  top: 44px;
}

.i22_4675_14_1660_14_560 {
  border: 1px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1660_14_561 {
  transform: rotate(-180deg);
  width: 2.096987247467041px;
  height: 42.93975830078125px;
  position: absolute;
  left: 397.624267578125px;
  top: 43.469879150390625px;
}

.i22_4675_14_1660_14_561 {
  border: 0.5px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1660_14_562 {
  transform: rotate(-180deg);
  width: 2.3628852367401123px;
  height: 41.872806549072266px;
  position: absolute;
  left: 398;
  top: 42.93305969238281px;
}

.i22_4675_14_1660_14_562 {
  border: 1px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_556 {
  transform: rotate(-180deg);
  width: 394.9296569824219px;
  height: 0.24726556241512299px;
  position: absolute;
  left: 397.21234130859375px;
  top: 43.25222396850586px;
}

.i22_4675_14_1661_14_556 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_557 {
  transform: rotate(-180deg);
  width: 1.093909740447998px;
  height: 43.201454162597656px;
  position: absolute;
  left: 397.21234130859375px;
  top: 43.201454162597656px;
}

.i22_4675_14_1661_14_557 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_558 {
  transform: rotate(-180deg);
  width: 393.27734375px;
  height: 0;
  position: absolute;
  left: 393.4833068847656px;
  top: 1.3029756546020508px;
}

.i22_4675_14_1661_14_558 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_559 {
  transform: rotate(-180deg);
  width: 0.4058450758457184px;
  height: 42.40964126586914px;
  position: absolute;
  left: 0.4061373174190521px;
  top: 43.469879150390625px;
}

.i22_4675_14_1661_14_559 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_560 {
  transform: rotate(-180deg);
  width: 1.8258600234985352px;
  height: 41.34939956665039px;
  position: absolute;
  left: 1.8262804746627808px;
  top: 44px;
}

.i22_4675_14_1661_14_560 {
  border: 1px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_561 {
  transform: rotate(-180deg);
  width: 2.096987247467041px;
  height: 42.93975830078125px;
  position: absolute;
  left: 397.624267578125px;
  top: 43.469879150390625px;
}

.i22_4675_14_1661_14_561 {
  border: 0.5px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1661_14_562 {
  transform: rotate(-180deg);
  width: 2.3628852367401123px;
  height: 41.872806549072266px;
  position: absolute;
  left: 398;
  top: 42.93305969238281px;
}

.i22_4675_14_1661_14_562 {
  border: 1px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_556 {
  transform: rotate(-180deg);
  width: 394.9296569824219px;
  height: 0.24726556241512299px;
  position: absolute;
  left: 397.21234130859375px;
  top: 43.25222396850586px;
}

.i22_4675_14_1662_14_556 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_557 {
  transform: rotate(-180deg);
  width: 1.093909740447998px;
  height: 43.201454162597656px;
  position: absolute;
  left: 397.21234130859375px;
  top: 43.201454162597656px;
}

.i22_4675_14_1662_14_557 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_558 {
  transform: rotate(-180deg);
  width: 393.27734375px;
  height: 0;
  position: absolute;
  left: 393.4833068847656px;
  top: 1.3029756546020508px;
}

.i22_4675_14_1662_14_558 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_559 {
  transform: rotate(-180deg);
  width: 0.4058450758457184px;
  height: 42.40964126586914px;
  position: absolute;
  left: 0.4061373174190521px;
  top: 43.469879150390625px;
}

.i22_4675_14_1662_14_559 {
  border: 2px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_560 {
  transform: rotate(-180deg);
  width: 1.8258600234985352px;
  height: 41.34939956665039px;
  position: absolute;
  left: 1.8262804746627808px;
  top: 44px;
}

.i22_4675_14_1662_14_560 {
  border: 1px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_561 {
  transform: rotate(-180deg);
  width: 2.096987247467041px;
  height: 42.93975830078125px;
  position: absolute;
  left: 397.624267578125px;
  top: 43.469879150390625px;
}

.i22_4675_14_1662_14_561 {
  border: 0.5px solid rgb(30 10 60 / 100%);
}

.ei22_4675_14_1662_14_562 {
  transform: rotate(-180deg);
  width: 2.3628852367401123px;
  height: 41.872806549072266px;
  position: absolute;
  left: 398;
  top: 42.93305969238281px;
}

.i22_4675_14_1662_14_562 {
  border: 1px solid rgb(30 10 60 / 100%);
}

.i22_4675_14_1663 {
  border: 2px solid rgb(169 168 179 / 100%);
}

/* .ei22_4675_19_2826 {
  width: 509px;
  height: 408px;
  position: absolute;
  left: 664px;
  top: 76px;
}

.ei22_4675_19_2827 {
  color: rgb(30 10 60 / 100%);
  width: 509px;
  height: 128px;
  position: absolute;
  left: 0;
  top: 80px;
  font-family: "Neue Plak";
  text-align: left;
  font-size: 52px;
  letter-spacing: 1;
  line-height: px;
}

.ei22_4675_19_2828 {
  color: rgb(30 10 60 / 100%);
  width: 509px;
  height: 96px;
  position: absolute;
  left: 0;
  top: 232px;
  font-family: "Neue Plak Text";
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.25;
  line-height: px;
}

.ei22_4675_19_2829 {
  width: 509px;
  height: 408px;
  position: absolute;
  left: 664px;
  top: 76px;
}

.ei22_4675_19_2830 {
  color: rgb(30 10 60 / 100%);
  width: 509px;
  height: 64px;
  position: absolute;
  left: 0;
  top: 112px;
  font-family: "Neue Plak";
  text-align: left;
  font-size: 52px;
  letter-spacing: 1;
  line-height: px;
}

.ei22_4675_19_2831 {
  color: rgb(30 10 60 / 100%);
  width: 509px;
  height: 96px;
  position: absolute;
  left: 0;
  top: 200px;
  font-family: "Neue Plak Text";
  text-align: left;
  font-size: 18px;
  letter-spacing: 0.25;
  line-height: px;
} */