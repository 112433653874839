.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
}

.hero__background {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../assets/hero_blob.png);
    background-repeat: no-repeat;
    background-size: cover;
}

/* .hero__showcase {
    position: relative;
    background-color: rgba(1, 36, 233, 1); 
    padding: 120px;
} */

/* .hero__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
} */

.hero__heading-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    color: rgba(247, 241, 84, 1);
    color: #F7F154;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 90%;
    font-family: 'Neue Plak';
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.hero__heading-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 101px;
    margin-top: -2px;
    color: rgba(143, 242, 247, 1);
    color: #8FF2F7;
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    font-size: 132px;
    line-height: 90%;
    font-family: 'Neue Plak';
    text-align: center;
    letter-spacing: 0.5px;
}

.img {
    position: relative;
    display: block;
}

.marker {
    position: absolute;
    top: 70vh;
    left: 50%;
    width: 10px;
    height: calc(30vh);
    z-index: 1;
    background-color: rgba(240, 85, 55, 1);
}