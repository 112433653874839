.form-example-section {
    /* background-color: rgba(255, 255, 255, 1); */
    /* width: 100%;
    height: 560px; */
    position: relative;
    background-image: url(../assets/blob.png);
    background-repeat: no-repeat;
    /* background-size: contain; */
    padding: 152px 109px;
    display: flex;
    justify-content: space-between;
    height: 50vh;
}

.vertical-divider {
    background-color: rgba(240.00000089406967, 85.0000025331974, 55.00000052154064, 1);
    width: 10px;
    height: 129px;
    position: absolute;
    left: 50%;
    top: 0px;
}

.marm-blob {
    width: 1200px;
    height: 1200px;
    position: absolute;
    left: 603px;
    top: -320px;
    background-image: url(../assets/blob.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
}

.form-examples {
    display: inline-block;
    padding: 0px 0px 0px 109px;
}

.input-field {
    background-color: rgba(255, 255, 255, 1);
    width: 398px;
    height: 44px;
    position: relative;
    margin-bottom: 24px;
    border: 1px solid black;
}

.input-field-3 {
    background-color: rgba(255, 255, 255, 1);
    width: 398px;
    height: 44px;
    position: relative;
    margin-bottom: 24px;
    border: 1px solid black;
}

.input-field-2 {
    background-color: rgba(255, 255, 255, 1);
    width: 398px;
    height: 44px;
    position: relative;
    border: 1px solid black;
    margin-bottom: 24px;
}

.label {
    font-family: 'Neue Plak';
    font-weight: 400;
    font-size: 84px;
    color: rgba(30, 10, 60, 1);
    width: 45px;
    height: 18px;
    position: absolute;
    left: 12px;
    top: 13px;
    font-family: Roboto Mono;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.5;
}

.button__label {
    color: rgba(30, 10, 60, 1);
    width: 43px;
    height: 20px;
    position: absolute;
    left: 32px;
    top: 12px;
    font-family: "Neue Plak Text";
    text-align: left;
    font-size: 14px;
    letter-spacing: 0;
    line-height: px;
}

.section-counter {
    transform: rotate(90deg);
    color: rgb(30 10 60 / 100%);
    width: 114px;
    height: 24px;
    position: absolute;
    left: 64px;
    top: 156px;
    font-family: "Neue Plak Text";
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.25;
    line-height: 18px;
}

.section-info {
    width: 509px;
}

.title {
    /* -align: left; */
    font-size: 52px;
    letter-spacing: 1px;
    display: block;
    font-family: 'Neue Plak';
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    line-height: 64px;
    letter-spacing: 1px;
    color: #1E0A3C;
}

.description {
    color: rgb(30 10 60 / 100%);
    width: 509px;
    height: 96px;
    display: block;
    font-family: "Neue Plak Text";
    text-align: left;
    font-size: 18px;
    letter-spacing: 0.25;
    line-height: 18px;
    margin-top: 48px;
    font-family: 'Neue Plak Text';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #39364F;
}

.button {
    background-color: rgb(255 255 255 / 100%);
    width: 107px;
    height: 44px;
    position: relative;
    margin-left: 48px;
    /* border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; */
    border-radius: 4px;
    border: 1px solid black;
}